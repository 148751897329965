<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        If a
        <stemble-latex content="$20.00\,\text{L}$" />
        bottle of a chemical costs
        <number-value :value="cost" prepend-text="\$" />, how many dollars would
        <stemble-latex content="$1.00\,\text{mL}$" />
        of that chemical cost?
      </p>

      <calculation-input
        v-model="inputs.studentAnswer"
        prepend-text="$\text{Cost (\$):}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemOleMiss116PL7_Q8part4',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      dollarSign: '&#36;',
    };
  },
  computed: {
    cost() {
      return this.taskState.getValueBySymbol('cost').content;
    },
  },
};
</script>
